<template>
  <div>
    <div class="dropdown lang-drop">
      <button class=" dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" @click="isDrop = !isDrop" >
        <i class="fa-light fa-globe"></i>
      </button>
      <ul class="dropdown-menu dropdown-menu-lg-end"  >
        <li v-for="element, i in dropDownLabel" v-bind:key="i">
          <a class="dropdown-item" :href="'#'+dropDownLink[i]"  @click="emitData(dropDownLink[i])">
            <!-- <img src="https://i.pinimg.com/736x/24/ba/06/24ba068ceb093840e2c9a95da1e4114c.jpg" /> -->
            {{element}}
          </a>
        </li>
      </ul>
      <!-- :class="{'is-drop': isDrop}" -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectButton',
  props: {
    uid: {
      type: String,
      default: 'cfa-select-button-01'
    },
    dropDownLabel: {
      type: Array,
      default: () => ['Link1', 'Link2']
    },
    dropDownLink: {
      type: Array,
      default: () => ['01', '02']
    },
    dropDownStyle: {
      type: Array,
      default: () => ['background-color: red !important;', 'background-color: #ddd !important;']
    },
    label: {
      type: String,
      default: 'BUTTON'
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    cssStyle: {
      type: String,
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      arrow: 'fa fa-plus',
      isDrop: false,
    }
  },
  methods: {
    showDropDown() {
      if (document.getElementById(this.uid).classList.contains('d-none')) {
        this.arrow = 'fa fa-chevron-down'
        document.getElementById(this.uid).classList.remove('d-none');
      }
      else {
        this.arrow = 'fa fa-plus'
        document.getElementById(this.uid).classList.add('d-none');
      }
    },
    emitData(data) {
      this.$emit('action', data)
    }
  },
  mounted() {
    // alert(document.getElementById(this.uid).classList.contains('d-none'))
    // document.body.addEventListener('click', (e) => {
    //   console.log(e.target)
    //   document.getElementById(this.uid).classList.add('d-none');
    // });
  }
}
</script>

<style scope>
.cfa-select-btn {
  border: 1px solid #eceff5 !important;
  border-radius: .5em !important;
  background-color: #fff !important;
}

.cfa-select-btn span i {
  font-size: .7em;
  position: relative;
  bottom: .2em;
  left: .4em;
}

.cfa-dropdown-list {
  background-color: #fff;
  padding: 0.2em;
  border: 1px solid #eceff5 !important;
  margin-top: .2em;
  border-radius: .5em !important;
  min-width: 10em;
  position: absolute;
  right: 1em;
  padding-bottom: 0.4em;
}

.cfa-dropdown-list button {
  width: calc(100% - .4em);
  margin-top: .4em;
  margin-left: .2em;
  margin-right: .2em;
}

.linkStyle:hover {
  color: var(--primary-color) !important;
}

/*.cfa-dropdown-list:last-of-type button{
  margin-bottom: 0em;
}*/
</style>