<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" css_class="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">{{ $t('quiz') }}</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
      <div v-if="subscriptionData?.is_activated == true && subscriptionData !=  null">
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div v-if="quiz != null" class="cfa-page">
            <div class="col-12 pe-0 pe-md-2 ">
              <div class="cfa-welcome ml-0">
                <div class="py-2 row d-flex align-items-center cfa-certif-state-line col-12 col-md-99 m-auto">
                  <br>
                  <!--<c-f-a-video :videoHeight="590" :maxHeight="45" :showLabel="false" :placeholder="staticImage" :videoLink="quiz.link_video" uid="cfa-quiz-intro-video-element" autoplay></c-f-a-video>-->
                  <c-f-a-video placeholder="./spectorly.svg" :autoplay="true" :video-link="quiz.link_video" :showLabel="false" :max-height="30.5" :video-height="400"></c-f-a-video>
                  <br>
                </div>
              </div>
              <div class="cfa-welcome ml-0 mt-3">
                <div class="text-center py-3 pt-4">

                  <div class="quiz-info">
                    <p class="quiz-title" style="font-size: 20px;">{{ quiz.title }}</p>
                    <p class="quiz-description" style="font-size: 15px;"><e style="font-weight: 400;">
                      Description: </e>{{ quiz.description }}
                    </p>
                  </div>
                  
                </div>
                <div class="text-center py-3 pb-4 launch-quiz d-flex justify-content-end">
                  <button @click="launchExam" v-if="subscriptionData != []" class="btn cfa-btn-primary fw-bolder" style="border-radius: 5px !important;">
                      {{ $t('CERTIFICATION') }}
                  </button>
                  <!-- <button v-else @click="alertWithoutCertif" class="btn cfa-btn-danger fw-bolder" style="border-radius: 5px !important;">
                      {{ $t('CERTIFICATION') }}
                  </button> -->
                </div>
              </div>
            </div>
          </div>
          <div v-else  class="col-12 pe-0 pe-md-2">
            <div class="cfa-slider-text-content">
              <div class="cfa-welcome ml-0 pt-5 d-flex align-items-center justify-vontent-center" style="height: 100vh;">
                <br><br><br>
                <div class='d-flex flex-column justify-content-between align-items-center py-5 w-100'>
                  <img width="150" src="../../assets/img/svg/cfa-ud-assets-05.svg" alt="cfa default image for no notification"/>
                  <p class="mt-5 text-center pb-5">{{ $t('oops') }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
            </div>
          </div>
        </div>
        <div v-show="certif">
      <div class="card-body p-0 mt-2 mb-0">
          <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
              <div class="d-flex align-items-center justify-content-between w-100">
                  <div>
                    {{ $t('the_certification_exams') }} 
                  </div>
                  
              </div>
          </div>
      </div>
        
      </div>
      </div>
      
      <div v-else>
      <div class="card-body p-0 mt-2 mb-0">
          <div class="card-transparent cfa-warning-deadline py-3 px-2 quiz-no-subscription" style="background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
              <div class="d-flex align-items-center justify-content-between w-100">
                  <div>
                    {{ $t('the_certification_exam') }} 
                  </div>
                  <div class="">
                      <a href="/facturation/pricing"><button class="btn cfa-btn-primary">{{ $t('subscribe') }}</button></a>
                  </div>
              </div>
          </div>
      </div>
        
      </div>
      </template>
    </c-f-a-nav>

    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import CFAVideo from '@/components/shared/video/CFAVideo.vue'
import { getAPI } from '@/axios-api.js'

export default {
  name: 'QuizView',
  components: {
    CFAFooter,
    CFANav,
    CFAVideo
  },
  data () {
    return {
      quiz_stats: null,
      subscriptionData: [],
      alertString: '',
      certif:false,
      waitingAPIResponse: true,
      quiz: null,
      staticImage: '',
    }
  },
  methods: {
    async getAllQuiz() {
      try {
        this.waitingAPIResponse = true;
        const [response1] = await Promise.all([
          getAPI.get(`quizz`),
        ]);
        if(response1.status == 200) {

          //console.log("Ici: ", response1.data)
          this.quiz = response1.data.results[0];
          console.log("id",this.quiz.id);
          if (this.quiz !== null) {
            this.getQuizStat();
          }
          //this.getQuizStat();
          
          this.waitingAPIResponse = false
        }
        else {
          this.waitingAPIResponse = false
          //this.alertString = `Une premiere erreur est survenu|alert-no|4000::`+Date.now()
        }
      }
      catch (e) {
        console.log("Une erreur est survenue dans getAllQuiz :", e);
        //this.alertString = `Une seconde erreur est survenu|alert-no|4000::`+Date.now()
      }
    },
    retrieveUserSubscription () {
            this.waitingAPIResponse = true
            getAPI.get(`subscriptions/find-subscription`).then(response => {
              if(response.status == 200) {
                this.subscriptionData = response.data.data

                if(this.subscriptionData != null){
                console.log("Infos: ", this.subscriptionData)
                }
                
                this.remain_days = this.subscriptionData.remain_days

                const created_at = new Date(this.subscriptionData.created_at)
                created_at.setDate(created_at.getDate() + 30);
                
                const expirationDate = created_at.toISOString();

                this.expiration_date = expirationDate
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPIResponse = false
              }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
      },
    alertWithoutCertif () {
        //let msg = "Oups ! vous n'avez encore souscris à aucun plan."
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    },
    getQuizStat () {
        getAPI.get(`stat_quizz/user/${localStorage.user}/${this.quiz[0].id}`)
            .then(response=>{
                if(response.status == 200) {
                    this.quiz_stats = (response.data.success) ? response.data.data : null;
                }
            })
            .catch(() => {
                console.log('An error occur while loading module !')
            })
    },
    async launchExam () {
        // if quiz stat do not exist then create
        if(this.quiz_stats == null) {
            await getAPI.post(`stat_quizz/`, {
                quizz: this.quiz.id,
                user: localStorage.user,
                note: "0",
                // progression: 1
            })
            .then(response=>{
                if(response.status == 201) {
                  console.log(response);
                  let a = document.createElement('a');
                    a.href=`/quiz/take-exam/${this.quiz.id}`;
                    a.click();
                  // if(response.data.success == false){
                  //   let msg = (response.data.message == 'You have not completed the required courses') ? '<div style="display: flex;justify-content: space-between; align-items: center; position: relative; top: -0.3em; width: ;"><span>Vous devez terminer vos modules avant de passer l\'examen final !</span><a href="/modules"><button style="background-color: #06bbc4; color: #fff; transition: all ease-in .2s; outline: none; border: none; border-radius: 5px; padding: 0.1em 0.5em; padding-top: 0.5em;">VOIR LES MODULES</button></a></div>' : response.data.message
                  //   this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  // }
                  // else{
                  //   let a = document.createElement('a');
                  //    a.href=`/quiz/take-exam/${this.quiz.id}`;
                  // }
                    // this.quiz_stats = (response.data.success) ? response.data.data : null;
                    // let a = document.createElement('a')
                    // a.setAttribute('href', `/modules-details/${this.id}`)
                    // a.click()
                    // a.remove()
                }
                if(response.status == 400){
                  this.certif=true;
                  let msg = "Vous devez terminer vos modules avant de passer l'examen final !";
                  this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    
                  
                }
                this.waitAPI = false
            })
            .catch((error) => {
            console.log(error)
                    //let msg = (error.response.data.message == 'Follow the modules before passing the text') ? '<div style="display: flex;justify-content: space-between; align-items: center; position: relative; top: -0.3em; width: ;"><span>Vous devez terminer vos modules avant de passer l\'examen final !</span><a href="/modules"><button style="background-color: #06bbc4; color: #fff; transition: all ease-in .2s; outline: none; border: none; border-radius: 5px; padding: 0.1em 0.5em; padding-top: 0.5em;">VOIR LES MODULES</button></a></div>' : error.response.data.message
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    this.waitAPI = false
                })
        }

        // next: redirect to exam room
        
            

            getAPI.get(`other/dashboard/`).then(response => {
                if(response.status == 200) {
                    this.subscriptionData = response.data.data.deadline
                    // a.click()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    // fake block - as endpoint do not exist
                    // a.click()
                    // -------------------------------------
                }
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
       
    }
  },
  mounted() {
    this.getAllQuiz()
    this.retrieveUserSubscription();
    this. getQuizStat();

    let playBtn = document.getElementById('cfa-quiz-intro-video-element-btn')
    if (playBtn != null) {
      playBtn.click()
    }
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  
}
.quiz-info {
  text-align: justify;
  width: 55em;
  margin: 0 auto;
  font-weight: 400;
  margin-left: 20px;
  color: #132B4D;
}
.quiz-description {
  font-weight: normal;
  color: #132B4D;
}
.quiz-title, .quiz-description {
  margin: 1em 0;
}
.quiz-title {
  font-weight: 400;
  color: #132B4D;
}
.launch-quiz {
  margin-right: 20px;
}
</style>
