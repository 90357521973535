<template>
    <div class="input-container country-code">
        <country-code class="country-dial" @code="changeCode"/>
        
        <input type="text" :placeholder="textHolder" :value="phoneNumber" @input="onInput" />
    </div>
</template>

<script >
import countryCode from './countryCode.vue';

export default {
    name: 'InputPhone',
    data () {
        return {
            phoneNumber : null,
            dialCode : '+33',
        }
    },
    emits : ['update:modelValueText', 'updateNumber'],
    props: {
        textHolder : {
            type : String,
            default : ''
        },
        modelValueText : {
            type : [String, Array]
        },
    },
    methods: {
        changeCode (country) {
            //this.dialCode = '(+' + country[2] + ')'
            this.dialCode = '+' + country[2] + ''
            this.country = country[0];
            let phone = this.dialCode  + this.phoneNumber;
            
            this.$emit('updateNumber', phone)
        },
        onInput(event) {
            let newValue = event.target.value;
            // Remove all non-numeric characters except decimal point (if needed)
            newValue = newValue.replace(/[^0-9]/g, '');
            let phone = this.dialCode  + newValue;
            // Emit the numeric value back to the parent
            this.$emit('updateNumber', phone);
            this.phoneNumber = newValue;

            // Update internal value
        },
    },
    components: {
        countryCode
    }
}

</script>