<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3 d-flex justify-content-between align-items-center efwegl">
            <router-link :to="{name: 'ModulePreview', params: {id: $route.params.id}}">
                <button class="btn cfa-btn-primary"><i class="fa fa-chevron-left">&nbsp;&nbsp;</i>{{ $t('back') }}</button>
            </router-link>
            <div>
                <h1 v-if="modulePayLoad != null" class="cfa-section-title mb-0 ms-2">
                    <!--<span class="cfa-primary">MODULE :</span>-->
                    {{ modulePayLoad.title.toUpperCase() }}
                </h1>
            </div>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <div class="courseIframe d-flex position-relative">
            <pre-loader v-show="waitingHttpResponse" />
            <div id="html_container"></div>
            <iframe id="course_iframe" src="" class="col-12" title="Spectorly - Module Content" style="background-color: var(--primary-light-color-2)"></iframe>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import PreLoader from '@/components/shared/PreLoader.vue'
import {getAPI} from "@/axios-api";

export default {
  name: 'WebContainerView',
  props: {
    /*https://iseazy.com/dl/f8658e755a02432e9235567ea8c68b4e#/slide/IYC8pSktB*/
    url: {
      type: String,
      default: ''
    }
  },
  components: {
    CFAFooter,
    CFANav,
    PreLoader
  },
  data () {
    return {
      waitingHttpResponse: true,
      alertString: '',
      modulePayLoad: null,
      url_content: this.url
    }
  },
  mounted() {
      document.querySelector('iframe').onload = () => {
        setTimeout(() => {
          this.waitingHttpResponse = false
        }, 2000)
      }
      // document.getElementById('cfa-menu-swiper').click()
      // document.getElementById('cfa-menu-swiper').click()
      document.getElementById('cfa-nav-bottom-swiper').click()

      // get module data
      getAPI.get(`modules/${this.$route.params.id}/`).then((response) => {
        if (response.status === 200) {
          this.modulePayLoad = response.data
          // this.url_content = this.modulePayLoad.link
          
        } else {
          //this.alertString = `Une erreur est survenu|alert-no|4000::` + Date.now()
        }
      })

      getAPI.post(`stat_module/`, {
        user: localStorage.user,
        module: this.$route.params.id
      }).then((response) => {
        if (response.status === 201) {
          this.url_content = response.data.data.link
          document.getElementById('course_iframe').setAttribute('src', this.url_content)
          document.getElementById('course_iframe').src = this.url_content;
          console.log("this.url_content", this.url_content)

          let iframe = document.getElementById('course_iframe');
          if (iframe) {
            iframe.contentDocument.body.innerHTML = "<div style='background: url(https://cci-occitanie.cyberspector.com/favicon.ico); background-position: center; background-repeat: no-repeat; width: 110%;height: 100vh;text-align: center; display: flex; align-items: center;justify-content: center;'><p>Un popup est ouvert</p></div>";
          }
          //let a = document.createElement('a');
          //a.setAttribute('target', '_blank')
          //a.setAttribute('href', this.url_content);
          //a.click();

          if (this.url_content) {
            /*const windowFeatures = 'width=800,height=600,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes';

            const newWindow = window.open(this.url_content, '_blank', windowFeatures);
            newWindow.focus();*/
          }


          /*

          */
        } else {
          //this.alertString = `Une erreur est survenu|alert-no|4000::` + Date.now()
        }
      });
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.courseIframe iframe{
    padding: 0;
    overflow-y: hidden;
  }
  .courseIframe{
    width: 100%;
    min-height: 100vh;
    /* margin-top: 5em; */
  }
  .iframeAction{
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  .bg-summary{
    background-color: #f3f3f3;
  }
</style>
