<template>
    <main class="inner-page page-auth">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12" >
            <div class="content">
              <div class="row">
                <div class="col-lg-6 content-left">
                  <div class="block text-center logo-wrapper">
                    <img
                      src="../../assets/auth/images/spectorly-logo.svg"
                      alt="Spectorly"
                      class="logo"
                    />
                  </div>
                  <div class="block text-center banner-wrapper">
                    <img src="../../assets/auth/images/icon-auth-banner.svg" alt="Auth banner" />
                  </div>

                  <div class="note sub-title-2">
                    Placez l’humain au coeur de votre Stratégie de
                    <span>Cyberdéfence</span>
                  </div>
                </div>

                <div class="col-lg-6 content-right form-auth-ctn" >
                  <form class=" form-2 form-auth form-auth--col " @submit.prevent="tryLogin" >
                    <div class="form-auth-head">
                      <h4>Bonjour !</h4>
                      <h2><span>Bienvenue</span></h2>
                      <div class="separator"></div>
                    </div>
                    <div class="form-auth-inputs">
                      <div class="" v-if="incorrect" >
                        Erreur : Adresse e-mail ou mot de passe incorrect.
                      </div>
                      <div class="">
                    
                        <ModernInput 
                        @inputChange="(e)=>{this.email = e}" :syncField="syncTime" :placeholder="'Votre  Email'" :errorText="'Champ requis'" :label="'Veuillez entrer votre adresse email'" :uid="'cfa-email-bloc'" :type="'email'" />
                        
                      </div>
                      <div class="form-group">
                        <ModernInput @inputChange="(e)=>{this.password = e}" :syncField="syncTime" placeholder="Mot de passe" errorText="Champ requis"  uid="cfa-password-bloc" type="password" />
                          <a href="/forgotpassword" class="forgot-password"> Mot 
                          de passe oublié ? </a>
                        
                      </div>
                      <div class="auth-form-link text-center ">
                          <p class="text">
                            </p>
                      </div>

                    </div>
                  
                    <div class="form-group submit-ctn">
                      <button
                        type="submit"
                        class="c-btn c-btn--secondary block w-100 spect-btn"
                      >
                        <span v-if="!waitingAPIResponse">Me connecter</span>
                        <img v-else src="@/assets/img/gif/load2.gif" alt="loading gif" style="width: 30px;"/>
                      </button>
                    </div>
                    <div class="container flex-ctn flex-ctn--col">
                      <div class="second-paragraph text-2">
                        Vous n’avez pas de comptes?
                        <a href="/signup" class="cursor-pointer">
                            <span>Créer un compte maintenant</span>
                        </a>
                      </div>
                      <p>
                        ou
                      </p>
                      <div class="first-paragraph">
                          <p class="text-2" >
                              <a href="/Register-with-code" class="cursor-pointer" >
                                  <span>S'inscrire avec un code</span>
                              </a>
                          </p>
                        </div>
                      <div style="width:30px;"></div>
</div>

                  <div class="policy " >
                    <p class="text-2 text-center" >En continuant, vous acceptez les termes de notre <a 
                      
                      href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank">politique de confidentialité</a> et nos réglementations concernant les <a  href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/">cookies</a>.</p>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container -->
      <!-- <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition> -->
    </main>
  </template>
  
  <script>
  import ModernInput from '@/components/shared/input/ModernInput.vue'
  // import { guestAPI } from '@/axios-api.js'
  // import Alert from '@/components/shared/alert/Alert.vue'
 import axios from "axios";
  export default {
    name: 'LoginView',
    components: {
     ModernInput,
      // Alert
    },
    data () {
      return {
        email: '', // genereux.akotenou@gmail.com
        password: '',  //00000000
        alertMe: false,
        gAlertMessage: '',
        gAlertType: '',
        waitingAPIResponse: false,
        waitingAPIResponse2: true,
        incorrect:false,
        syncTime: null,
        pub: null,
        showPassword:false,
        inputType:'password'
      }
    },
    methods: {
      show(){
        this.showPassword=!this.showPassword;
      },
      ToggleButtonIcon() {
      this.inputType =
        this.inputType === "password" ? "text" : "password";
    },
      displayError(error, type='alert-ok') {
        this.gAlertMessage = error
        this.gAlertType = type
        this.alertMe = true
        setTimeout(() => {
          this.alertMe = false
        }, 5000);
      },
      tryLogin () {
        if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)
            || this.password.length < 2) {
          this.displayError('Oups ! Veuillez remplir les champs convenablement.', 'alert-no');
          this.syncTime = Date.now().toString()
        }
        else{
          this.waitingAPIResponse = true
          axios.post(`${process.env.VUE_APP_BASE_URL}accounts/login`,
              {
                email: this.email,
                password: this.password,
              })
              .then(response => {
                if(response.status == 200) {
                  const token = response.data.data.access
                  const refresh_token = response.data.data.refresh
                  const user_data = response.data.data.user
                  const msg = response.data.message
                  localStorage.setItem('token', token)
                  localStorage.setItem('refresh_token', refresh_token)
                  localStorage.setItem('user', user_data.id)
                  localStorage.setItem('firstname', user_data.firstname)
                  localStorage.setItem('lastname', user_data.lastname)
                  localStorage.setItem('organisation', user_data.organisation)
                  localStorage.setItem('type_account', user_data.type_account)
                  localStorage.setItem('role', user_data.role)
                  localStorage.setItem('image', null)
  
                  //redirect to dashbaord
                  this.$emit('connected', token)
                  this.displayError(msg, 'alert-yes')
                  setTimeout(() => {
                    this.waitingAPIResponse = false
                    let a = document.createElement('a');
                    a.href="/dashboard"
                    a.click()
                    a.remove()
                  }, 1000);
                }
                else {
                  this.displayError('Oups ! something went wrong.', 'alert-no')
                  this.waitingAPIResponse = false
                }
              })
              .catch(error => {
                localStorage.removeItem('token')
                if (error.response) {
                  if(error.response.data.message == "Invalid Credentials") {
                    this.incorrect=true;
                    this.displayError("Adresse email ou mot de passe incorrect(e)", 'alert-no', 10000)
                  }
                  else if(error.response.data.message == "Your account has been blocked. Please check your email for verification code.") {
                    this.displayError("Votre compte a été bloqué. Veuillez vérifier votre messagerie électronique pour obtenir le code de vérification.", 'alert-no', 10000)
                  }
                  else{
                    this.displayError(error.response.data.msg, 'alert-no', 10000)
                  }
                }
                else if (error.request) {
                  this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
                }
                else {
                  this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                }
                this.waitingAPIResponse = false
              })
        }
      }
    },
    mounted() {
      this.waitingAPIResponse2 = true
      axios.get(`${process.env.VUE_APP_BASE_URL}pub?key=CONNEXION`).then(response => {
        if(response.status == 200) {
          this.pub = response.data[0]
        }
        else{
          console.log('Something went wrong !')
        }
        this.waitingAPIResponse2 = false
      }).catch(() => {
        console.log('Something went wrong !')
        this.waitingAPIResponse2 = false
      });
    }
  }
  </script>
  
  