<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">{{ $t('resources') }}</h1>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div>
            <content-filter :total1="totalModule" :total2="totalInProgress" label1="Tous" label2="Favoris" @viewMode="getViewMode" @viewFilter="getViewFilter"></content-filter>
          </div>
          <div v-if="this.allResources.length == 0" class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="d-flex flex-column">
              <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
              <span class="mt-3 fs-5 fw-bold">{{ $t('nothing_yet') }}</span>
            </div>
          </div>
          <div v-else>
            <div class="row pt-3">
              <course-card2  v-for="resource, i in allResources" v-bind:key="i" :unlock="unlocked" :id="resource.id" :title="resource.title" :description="resource.description" label="Module 1" :uid="'cfa-modules-assets-'+i" :viewMode='displayMode' :filterMode="filterMode" :link="resource.resources_link" :preview="resource.resources_image||''" ></course-card2>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ContentFilter from '@/components/shared/ContentFilter.vue'
import CourseCard2 from '@/components/shared/course/CourseCard2.vue'
import { getAPI } from '@/axios-api.js'

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    ContentFilter,
    CourseCard2
  },
  data () {
    return {
      waitingAPIResponse: true,
      displayMode: 'GROUP',
      filterMode: 'ALL',
      alertString: null,
      allResources: [],
      totalInProgress: 0,
      totalModule: 0,
      subscriptionData: [],
    }
  },
  methods: {
    getViewMode (e) {
      this.displayMode = e
    },
    getViewFilter (e) {
      this.filterMode = e
    },
    // retrieveUserSubscription () {
    //     getAPI.get(`subscriptions?user_id=${localStorage.user}`).then(response => {
    //         if(response.status == 200) {
    //             this.subscriptionData = response.data
    //         }
    //         else {
    //             //let msg = 'Oups ! something went wrong.'
    //             //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //         }
    //     }).catch(error => {
    //       if (error.response) {
    //           //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
    //       }
    //       else if (error.request) {
    //           //let msg = 'The request was made but no response was received. Please check your network.'
    //           //this.alertString = `${msg}|alert-no|8000::`+Date.now()
    //       }
    //       else {
    //           //let msg = 'Oups ! something went wrong.'
    //           //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //       }
    //       this.waitingAPIResponse = false
    //     })
    // }
  },
  mounted() {
    //this.retrieveUserSubscription();

    this.waitingAPIResponse = true
    getAPI.get('ressources')
    .then(response => {
      if(response.status == 200) {
        this.allResources = response.data.results
        this.totalModule = response.data.count
        // TODO: make this dynamic
        this.totalInProgress = 0
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      setTimeout(() => {
        this.waitingAPIResponse = false
      }, 2000);
    })
    .catch(error => {
      if (error.response) {
        //this.alertString = `${error.response.data.detail}|alert-no|10000::`+Date.now()
      }
      else if (error.request) {
        //let msg = 'The request was made but no response was received. Please check your network.'
        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      this.waitingAPIResponse = false
    })
  },
  watch: {
      subscriptionData(value) {
          if(value.length != 0) {
              this.unlocked = true
          }
      }
  }
}
</script>

<style scoped>
</style>
