<template>
  <div>
    <div v-if="true" class="cfa-bg-secondary cfa-footer-wrapper">
      <div class="container_ col-12 p-0 p-md-4 d-flex flex-md-row flex-column cfa-footer">
       

        <div>
          <ul class="footer-links">
            <li><a href="">{{ $t('cookies') }}</a></li>
            <li class="sm-d-none"> <span> | </span> </li>
            <li><a href="">{{ $t('privacy_policy') }}</a></li>
            <li class="sm-d-none"> <span> | </span> </li>
            <li><a href="">{{ $t('terms') }}</a></li>
          </ul>
        </div>

          <div class="d-block d-md-none col-12 text-start px-5">
            <hr>
            <news-letter class="mt-3 w-100"></news-letter>
            <social-icon class="mt-3 w-100"></social-icon>
            <select-button @action="changeLang" :dropDownLabel="['🇨🇵️ Fr', '🇺🇸️ En']" :dropDownLink="['go-to-fr', 'go-to-en']" label="<span>LANGUE </span><i class='fa fa-globe' style='font-size: 1.4em; color: #06bbc4 !important; position: relative; top: .05em;'></i>" uid="cfa-lang-swiper-bottom" :showArrow="false" cssStyle="border: none !important; position: absolute !important;right: 0em;width: 8em;bottom: 1em;" cssClass="d-md-none d-block"></select-button>
          </div>
        </div>
        <hr class="footer-line">
      <center><span style="font-weight: 400">© 2023 CyberSpector - <a style="color: var(--primary-color);" href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank" class="text-decoration-none">{{ $t('legal_notice') }}</a> - <a style="color: var(--primary-color);" href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank" class="text-decoration-none">{{ $t('terms_of_service') }}<br/><br/></a></span></center>
    </div>
  </div>
</template>

<script>
import NewsLetter from '@/components/shared/NewsLetter.vue'
import SocialIcon from '@/components/shared/SocialIcon.vue'
// import AiChat from '@/components/shared/chat/AiChat.vue'
import SelectButton from '@/components/shared/button/SelectButton.vue'
import { getAPI } from '@/axios-api.js'

export default {
  name: 'CFAFooter',
  components: {
    NewsLetter,
    SocialIcon,
    // AiChat,
    SelectButton
  },
  data () {
    return {
      country_maker: ['Bénin', 'France', 'Rwanda']
    }
  },
  methods: {
    shuffle (array){
      let currentIndex = array.length, ramdomIndex

      while (currentIndex != 0) {
        // pick a ramaining element
        ramdomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // swap it with the current one
        [array[currentIndex], array[ramdomIndex]] = [
          array[ramdomIndex], array[currentIndex]
        ]
      }

      return array
    },
    changeLang (e) {
      var lang = 'FR';
      
      if (e == "en") {
        lang = 'EN';
      }
      localStorage.setItem('language', e)
    
      getAPI.put(`accounts/users/${sessionStorage.user}/`, {
        firstname: sessionStorage.firstname,
        lastname: sessionStorage.lastname,
        phone: sessionStorage.phone,
        position: null,
        language: lang
      })
      .then(response => {
        if (response.status == 200) {
          //this.alertString = "Profil mis a jour avec succès|alert-yes|5000::"+Date.now()
          if(localStorage.language == 'en' && e == "fr") {
          localStorage.setItem('language', 'fr')
          }
          if(localStorage.language == 'fr' && e == "en") {
              localStorage.setItem('language', 'en')
          }
          this.$i18n.locale = localStorage.language
          this.lang = (localStorage.language == 'en') ? 'en' : 'fr'
        }
        else {
          this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
        }
      }).catch(() => {
        this.alertString = "Veuillez remplir convenablement les champs.|alert-no|5000::"+Date.now()
      })
        // alert('ok')
    },
  },
  mounted () {
    setInterval(()=>{
      this.shuffle(this.country_maker)
    }, 2000)

    // TODO: Temp Patch for content protection
    /*window.addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });
    window.addEventListener('keydown', (e) => {
        if (e.ctrlKey && (e.key === 'u' || e.key === 'U' || e.key === 'c' || e.key === 'C' || e.key === 'i' || e.key === 'I')) {
            e.preventDefault();
        }
    });*/

      /*const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/gh/yasserelsaid/chatbot@latest/index.min.js'
      script.id = 'botnet-docx-k9-bwex22'
      document.body.appendChild(script)

      const checkChatbaseButton = () => {
          const chatbaseButton = document.getElementById('chatbase-bubble-button')
          if (chatbaseButton) {
              chatbaseButton.style.background = '#06bbc4'
              return
          }
          setTimeout(checkChatbaseButton, 1000)
      }
      checkChatbaseButton()*/
  }
}
</script>

<style scoped>
.cfa-footer div ul li strong, .cfa-footer div ul li a{
  color: #fff;
  text-decoration: none;
}
.cfa-footer div ul{
  list-style-type: none;
  text-align: left;
  font-size: 1.1em;
}
.cfa-footer div ul li strong{
  font-size: 1.5em;
}
.cfa-newsletter-text{
  font-size: 1.1em;
}
.cfa-footer div ul li a:hover{
  color: var(--primary-color);
  text-decoration: underline;
}

.cfa-footer-brand{
  /*border-bottom: 1px solid #ddd;*/
}
#chatbase-bubble-button{
  background: var(--primary-color);
}
.cfa-footer-wrapper{
}
.footer-line {
  border: none;
  height: 1px;
  background-color: #ffffff;
  margin-top: 10px; 
}
.element {
  margin-left: 600px;
}

@media only screen and (max-width: 767px) {
  .element {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .element {
    margin-left: 150px;
  }
}

  @media only screen and (min-width: 1024px) {
  .element {
    margin-left: 600px;
  }
}
</style>
