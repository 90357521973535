<template>
    <div class="card bg-base-100 base-card">
        <div class="card-body">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script setup lang="ts" >

    

</script>