import axios from "axios";


const BaseURL = process.env.VUE_APP_BASE_URL

//const BaseURL =
  //process.env.NODE_ENV === "development"
    //? "https://api-dev-cfa.cyberspector.xyz/api/"
    //: "https://api-dev-cfa.cyberspector.xyz/api/";

//Guest
const guestAPI = axios.create({
  baseURL: BaseURL,
});

//Authorise
const getAPI = axios.create({
  baseURL: BaseURL,
  headers:
    localStorage.getItem("token") !== undefined
      ? { Authorization: `Bearer ${localStorage.getItem("token")}` }
      : {},
});

//Interceptor
getAPI.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // console.log(config);
    // console.log(config.headers);
    // console.log(config.method);
    // console.log(config.url);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function promptTokenExpire() {
  let acceptLogin = 0;
  while (!acceptLogin) {
    acceptLogin = confirm("Your session expire. Please Login to continue.");
  }
  localStorage.clear();
  let a = document.createElement("a");
  a.setAttribute("href", "/login");
  a.click();
  a.remove();
}

getAPI.interceptors.response.use(
  function (response) {
    console.log(response)
    return response;
  },
  function (error) {
    console.log(error)
    if (
      error.response.data.code === "token_not_valid" ||
      error.response.data.detail === "Given token not valid for any token type"
    ) {
      getAPI
        .post("accounts/login/refresh/", {
          refresh: localStorage.getItem("refresh_token"),
        })
        .then((response) => {
          // try get new access token
          if (response.status === 200) {
            const token = response.data.access;
            localStorage.setItem("token", token);
          } else {
            // prompt if refresh token expire
            promptTokenExpire();
          }
        })
        .catch(() => {
          // prompt if refresh token expire
          promptTokenExpire();
        });
    } else {
      return Promise.reject(error);
    }
  }
);

export { getAPI, guestAPI, BaseURL };
