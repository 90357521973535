<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        <div class="d-flex align-items-center justify-content-between w-100 pt-5 text-start ftwru">
          <h1 class="cfa-section-title">{{ $t('subscription') }}</h1>
          <a href="/facturation/pricing">
              <button class="btn cfa-btn-primary" style="height: 2.7em;">
                  {{ $t('available_offer') }}&nbsp;<i class="fa fa-long-arrow-alt-right">&nbsp;</i>
              </button>
          </a>
        </div>
        <br>
      </template>
      <!-- <template v-slot:content>
        <-- subscription list ->
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div class="">
              <button class="btn cfa-btn-light-custom">SOUSCRIPTION ACTUELLE</button>
              <Table min-height="5em" :hide-control="true" :columns="subscriptionsColumns" :data="subscriptions" :columns-action="subscriptionsToListen" @action="getCallToAction" last-cell-width="0"></Table>
          </div>
          <br><br>
        </div>

        <--facturation list->
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div class="">
              <button class="btn cfa-btn-light-custom">PAIEMENTS EFFECTUÉS</button>
              <Table min-height="5em" :hide-control="true" :columns="facturationColumns" :data="facturations" :columns-action="facturationsToListen" @action="getCallToAction" last-cell-width="0"></Table>
          </div>
          <br><br>
        </div>

        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div>
          </div>
        </div>
      </template> -->
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div>
            <!-- <button
              class="btn cfa-btn-light-custom"
              :class="{ 'selected-button': currentView === 'subscriptions' }"
              @click="showSubscriptions"
            >
              SOUSCRIPTION ACTUELLE
            </button> -->
            <button
              class="btn cfa-btn-light-custom active"
              :class="{ 'selected-button': currentView === 'facturations' }"
              @click="showFacturations"
            >
              {{ $t('payment_made') }}
            </button>
              <!-- :columns="currentView === 'subscriptions' ? subscriptionsColumns : transactionsColumns"
              :data="currentView === 'subscriptions' ? subscriptions : transactions"
              :columns-action="currentView === 'subscriptions' ? subscriptionsToListen : transactionsToListen" -->
            <Table
              min-height="5em"
              :hide-control="true"
              :columns="currentView === 'subscriptions' ? transactionsColumns : transactionsColumns"
              :data="currentView === 'subscriptions' ? transactions : transactions"
              :columns-action="currentView === 'subscriptions' ? transactionsToListen : transactionsToListen"
              @action="getCallToActionForTransactions"
              last-cell-width="0"
            ></Table>
          </div>
          <br><br>
        </div>

        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div>
          </div>
        </div>
      </template>

    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <!-- create -->
    <scalable-modal @callback="addNewPlan" :break="exitAddPlanModal" display-mode="block"  label="CRÉER UN PLAN" uid="cfa-modal-add-plan" @close="newPlan=false" :open="newPlan" :advancedControl="true" validationButtonText="Mettre à jour" exitButtonText="Annuler" :style="'width: 85vw; height: auto; margin-top: 5em; margin-bottom: 2em; background-color: #f0f0f0;'">
        <template v-slot:content>
            <Table v-show="!waitingAPIResponse" :columns="plansColumns" :data="plans" :columns-action="plansToListen" @action="getCallToAction1" last-cell-width="0"></Table>
            <div v-show="waitingAPIResponse" class="cfa-page">
                <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                    <div class="preloader_center d-flex flex-column">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <p class="pl-3 fw-bold">chargement . . .</p>
                    </div>
                </div>
            </div>
        </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";
import scalableModal from "@/components/shared/modal/ScalableModal.vue";

export default {
    name: 'FacturationView',
    components: {
        CFAFooter,
        CFANav,
        Table,
        scalableModal,
    },
    data () {
      return {
          waitingAPIResponse: false,
          subscriptionData: [],
          subscriptions: [],
          subscriptionsColumns: ['PLAN', 'MONTANT', 'DATE DEBUT', 'JOURS RESTANTS', 'STATUT'],
          subscriptionsToListen: ['ACTIONS'],
          newPlan: false,
          exitAddPlanModal: null,
          plans: [],
          plansColumns: ['N°', 'Nom', 'Prix', 'Capacité', 'Modules', 'Type', 'Autres'],
          plansToListen: ['Autres'],
          alertString: '',
          dashboardData: null,
          dateEnd: null,
          transactionsData: [],
          transactionsColumns: ['PLAN', 'MONTANT', 'DATE DE PAIEMENT', 'PROCHAIN PAIEMENT', 'MOYEN DE PAIEMENT', "FACTURE"],
          transactionsToListen: ['FACTURE'],
          transactions: [],
          user: localStorage.getItem('user'),
          currentView: 'subscriptions',
          errorSubscription: [],
          invoiceUrl: ""
      }
    },
    methods: {
      hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${localStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${localStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
        // showSubscriptions() {
        //   this.currentView = 'subscriptions';
        // },
        showFacturations() {
          this.currentView = 'facturations';
        },
        getAlert (payload) {
        console.log(payload)
            //this.alertString = `${payload}|alert-no|10000::`+Date.now()
        },
        retrieveUserSubscription () {
          this.waitingAPIResponse = true 
          getAPI.get(`subscriptions/find-subscription`).then(response => {
          //getAPI.get(`subscriptions?user_id=${localStorage.user}`).then(response => {
            if(response.status == 200) {
              this.subscriptionData = response.data.data

              console.log(this.subscriptionData.is_activated)

              this.retrieveUserTransactions()

              this.subscriptions = []

              //let row = this.subscriptionData
              let row = this.subscriptionData;
              //console.log("Data: ", row)

              let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
              this.subscriptions.push({'PLAN': `<span style="font-weight: 400;">${/*row.plan.name*/"plan_name"}</span>`, 'MONTANT': 'montant' + " XOF", 'DATE DEBUT': this.convertDate(row.created_at, 'date_only'), 'JOURS RESTANTS': row.remain_days, 'STATUT': statut,name: row.id, id: row.id})

              // subscriptionsColumns: ['PLAN', 'MONTANT', 'MODULES', 'DATE DEBUT', 'DATE FIN', 'STATUT'],
              // for(let i=0; i<this.subscriptionData.length; i++) {
              //   let row = this.subscriptionData[i]

              //   // let modules = '<div class="text-start col-12 d-flex flex-wrap">';
              //   // for (let j = 0; j < row.list_modules.length; j++) {
              //   //   modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
              //   // }
              //   // modules += '</div>'

              //   // let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
              //   // // classify and add to array
              //   // this.subscriptions.push({'N°': (i+1), 'PLAN': `<span style="font-weight: 400;">${row.plan.name}</span>`, 'MONTANT': "$" + row.plan.price_month + " / mois", 'MODULES': modules, 'DATE DEBUT': this.convertDate(row.start_date, 'date_only'), 'DATE FIN': this.convertDate(row.end_date, 'date_only'), 'STATUT': statut,name: row.id, id: row.id})
                
              //   let statut = (row.is_activated) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action" style="background-color: var(--primary-color)"><i class="fa fa-check"> &nbsp;&nbsp;</i>PLAN ACTIF</button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-lock"></i> PLAN INACTIF</button>`;
              //   // classify and add to array
              //   this.subscriptions.push({'N°': (i+1), 'DATE DE CREATION': `<span style="font-weight: 400;">${row.created_at}</span>`, 'JOURS RESTANTS': `<span style="font-weight: 400;">${row.remain_days}</span>`, 'STATUT': statut,name: row.id, id: row.id})
              // }
//              console.log(this.subscriptions)
          }
            else {
              ////let msg = 'Oups ! Vous n\'avez aucune souscription.'
              ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          }).catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              ////let msg = 'Oups ! something went wrong.'
              ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
        },
     retrieveUserTransactions () {
          this.waitingAPIResponse = true
            getAPI.get(`/transactions?user_id=${localStorage.getItem('user')}`)
            .then(response => {
              if(response.status == 200) {
                this.transactionsData = response.data
                
                this.transactions = []

                // transactionsColumns: ['PLAN', 'MONTANT', 'DATE DE PAIEMENT', 'PROCHAIN PAIEMENT', 'MOYEN DE PAIEMENT'],
                //for(let i=0; i<this.transactionsData.length; i++) {
                  
                  let row = this.transactionsData[this.transactionsData.length -1]
                  // let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                  // for (let j = 0; j < row.list_modules.length; j++) {
                  //   modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
                  // }
                  // modules += '</div>'
                  console.log(row)
                  this.transactions.push({'PLAN': `<span style="font-weight: 400;">${row.plan.name}</span>`, 
                  'MONTANT': `<div>${row.amount + " "+ row.currency}</div>`, 
                  'DATE DE PAIEMENT': `<div>${this.convertDate(row.start_date, 'date_only')}</div>`,
                  'PROCHAIN PAIEMENT': `<div>${this.convertDate(row.end_date, 'date_only')}</div>`, 
                  'MOYEN DE PAIEMENT': `<div>${row.aggregator}</div>`,
                  'FACTURE' : `<button btn-id="${row.invoice_code || row.code}" btn-action="download" class="ms-2 cfa-btn-primary btn btn-action">TÉLÉCHARGER</button>`,
                })
                
                //}
                // console.log('=============================')
                // console.log(this.transactions)
              }
              else {
               // //let msg = 'Oups ! something went wrong.'
                ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse = false
              }, 2000);
            }).catch(error => {
              if (error.response) {
                //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                ////let msg = 'Oups ! something went wrong.'
               // //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
            })
        },
        checkCode(numb) {
          const idPattern = /^\d+$/;
          let isNumb = false;
          if (idPattern.test(numb)) {
            isNumb = true;
          }
          return isNumb;
        },
        getCallToActionForTransactions (payload) {
            if(payload.action_type === 'download') {
              console.log("Invoice id: ", payload.id)
              const codeIsNumb = this.checkCode(payload.id)
              if (codeIsNumb == true){
                console.log("Facture feda")
                getAPI.get(`transactions/dowload-invoice-feda/${payload.id}`)
                    .then(response => {
                        if(response.status == 200) {
                            this.invoiceUrl = response.data.invoice_pdf_url;
                            //this.getAllTemplate();
                            console.log("URL: ", this.invoiceUrl);
                            window.location.href = this.invoiceUrl;
                            //window.open(this.invoiceUrl, '_blank');
                            //let msg = 'Facture téléchargée avec succès!'
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
          }
          else {
            console.log("Facture stripe")
              getAPI.get(`transactions/dowload-invoice/${payload.id}`)
                    .then(response => {
                        if(response.status == 200) {
                            this.invoiceUrl = response.data.invoice_pdf_url;
                            //this.getAllTemplate();
                            console.log("URL: ", this.invoiceUrl);
                            window.location.href = this.invoiceUrl;
                            //window.open(this.invoiceUrl, '_blank');
                            //let msg = 'Facture téléchargée avec succès!'
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
            }
          }
        },
        getDashboardData () {
            this.waitingAPIResponse = true
            getAPI.get(`other/dashboard/`)
              .then(response => {
                  if(response.status == 200) {
                      this.dashboardData = response.data.data
                      this.dateEnd = this.dashboardData?.deadline
                  }
                  else {
                      ////let msg = 'Oups ! something went wrong.'
                      ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  setTimeout(() => {
                      this.waitingAPIResponse = false
                  }, 2000);
              })
              .catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    ////let msg = 'Oups ! something went wrong.'
                    ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
        },
        convertDate(dateString, format='full') {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if(format==='full') {
                return `<b>${formattedDate} à ${formattedTime}</b>`;

            }
            return `<b>${formattedDate}</b>`;
        },
        addNewPlan () {
            alert('oulala')
        },
        openModal () {
            this.newPlan=true
            this.getPlans()
        },
        getPlans () {
            this.waitingAPIResponse = true
            getAPI.get(`plans/`)
                .then(response => {
                    if(response.status == 200) {
                        this.plans = response.data.results
                        let lgth = response.data.count
                        let plans = this.plans
                        //console.log("Plans: ", plans[0].price_month)
                        for(let i=0; i<lgth; i++) {
                            let row = plans[i]
                            let autres = `<div class="d-flex align-items-center">
                                  <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-update"></i> ACTIVER CE PLAN</button>
                             </div>`

                            // let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                            // for (let j = 0; j < row.modules.length; j++) {
                            //     modules += `<div style="cursor: pointer; border: 1px solid #ccc; padding: 0.5em; margin: 0.5em; border-radius: 0.25em;">Module ${row.list_modules[j].number}</div>`;
                            // }
                            // modules += '</div>'

                            if(row.type_plan == localStorage.type_account) {
                                this.plans.push({'N°': (i+1),'Nom': row.name, 'Prix': this.formatPrice(row.price_month), 'Capacité': row.duration, 'Type': row.type_plan, 'Autres':autres, name: row.id})
                            }
                        }
                    }
                    else {
                        ////let msg = 'Oups ! something went wrong.'
                        ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                       // //let msg = 'Oups ! something went wrong.'
                        ////this.alertString = `${msg}|alert-no|10000::`+Date.now()
                        ////this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                       // //let msg = 'Oups ! something went wrong.'
                        ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        formatPrice(price) {
          const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'XOF',
          });
          return formatter.format(price);
        },
      //   getCallToAction1(payload) {
      //     if(payload.action_type == 'delete') {
      //         this.tempDeleteID = payload.id;

      //         getAPI.post('subscriptions/', {
      //             user: localStorage.user,
      //             plan: payload.id,
      //         })
      //         .then(response => {
      //             if(response.status == 201) {
      //                 this.retrieveUserSubscription()
      //                 this.addNewPlan = false;
      //                 //let msg = 'Souscription créé avec succès!'
      //                 //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
      //             }
      //             else {
      //                 //let msg = 'Oups ! something went wrong.'
      //                 //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      //             }
      //         })
      //         .catch(error => {
      //             if (error.response) {
      //                 let firstError = Object.values(error.response.data)[0][0]
      //                 //this.alertString = `${firstError}|alert-no|10000::`+Date.now()
      //             }
      //             else if (error.request) {
      //                 //let msg = 'The request was made but no response was received. Please check your network.'
      //                 //this.alertString = `${msg}|alert-no|8000::`+Date.now()
      //             }
      //             else {
      //                 //let msg = 'Oups ! something went wrong.'
      //                 //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      //             }
      //             this.addNewPlan = false;
      //         })
      //     }
      // },
  },
    mounted() {
      this.getPlans()
      this.retrieveUserSubscription()
      this.retrieveUserTransactions()
      //this.getDashboardData()
    }
}
</script>

<style scoped>
.selected-button {
  background-color: var(--primary-color);
  color: white;
  margin-right: 10px;
}
.cHKOfE {
    background: linear-gradient(103.75deg, var(--primary-color) -13.9%, var(--primary-color) 79.68%);
    color: rgb(3, 3, 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    padding: 1.5rem 5rem;
    margin: auto;
    margin-top: 3em;
}
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.25em;
}
.bRktZd {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    list-style-type: none;
}
</style>
